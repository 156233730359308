<template>
  <div id="uploads-container">

    <v-tabs
      v-model="tab"
      background-color="primary"
      centered
      dark
    >
      <v-tabs-slider></v-tabs-slider>

      <v-tab href="#tab-1">
        Attendance
      </v-tab>

      <v-tab href="#tab-2">
        Quiz
      </v-tab>

      <v-tab href="#tab-3">
        Assignment
      </v-tab>

      <v-tab href="#tab-4">
        Course
      </v-tab>

      <v-tab href="#tab-5">
        MOOC
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item
        key="1"
        value="tab-1"
      >
        <v-card flat>
          <v-card-title>
            Upload Attendance Data
          </v-card-title>

          <v-card-text>
            <v-alert
              border="left"
              colored-border
              color="primary"
              type="info" 
              dense
              text
            >
              Date column will help derive the Course/Module. 
            </v-alert>

            <v-file-input
              v-model="attendanceFiles"
              counter
              label="File input"
              multiple
              placeholder="Select your files"
              outlined
              :show-size="1000"
            >
              <template v-slot:selection="{ index, text }">
                <v-chip
                  v-if="index < 2"
                  label
                  small
                  color="primary"
                >
                  {{ text }}
                </v-chip>

                <span
                  v-else-if="index === 2"
                  class="text-overline grey--text text--darken-4 mx-2"
                >
                  +{{ attendanceFiles.length - 2 }} File(s)
                </span>
              </template>
            </v-file-input>

            <v-alert
              dense
              text
              class="mt-1"
            >
              Shown below is the excel format with one row of sample data. Date is in YYYY-MM-DD or DD/MM/YYYY format. 
              Attendance status either a 'Y' or 'N'.
            </v-alert>

            <v-data-table
              dense
              :headers="attendanceHeaders"
              :items="attendanceDetails"
              hide-default-footer
              item-key="name"
              class="elevation-0"
              style="width: 100%;"
            >
            </v-data-table>
          </v-card-text>

          <v-card-actions class="dense">
              <v-btn class="ml-2" color="primary" :disabled='!attendanceUpdateEnable' @click="uploadAttendanceStudent">
                Upload
              </v-btn>
              <p class="mt-3 ml-4 mr-2 text-right" style="width: 100%;">
                Processed  <strong class="primary--text"> {{this.addedAttendance}}</strong> . 
                Failed <strong class="primary--text"> {{this.failedAttendance}} </strong>
              </p>
          </v-card-actions>
        </v-card>
      </v-tab-item>

      <v-tab-item
        key="2"
        value="tab-2"
      >
        <v-card flat>
          <v-card-title>
            Upload Quiz Scores
          </v-card-title>

          <v-row class="ml-3 mr-3">
            <v-col
              md="6"
              sm="12"
              cols="12"
            >
              <v-select
                :items="quizes"
                label="Select Quiz"
                item-text="quizName"
                item-value="id"
                persistent-hint
                single-line
                return-object
                v-model="selectedQuiz"
              >
              </v-select>
            </v-col>


            <v-col
              cols="12"
              sm="12"
              md="6"
            >
              <v-menu
                v-model="openMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="quizDate"
                    label="Quiz Date"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="quizDate"
                  @input="openMenu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>

          <v-card-text>
            <v-file-input
              v-model="quizfiles"
              counter
              label="File input"
              multiple
              placeholder="Select your files"
              outlined
              :show-size="1000"
            >
              <template v-slot:selection="{ index, text }">
                <v-chip
                  v-if="index < 2"
                  label
                  small
                  color="primary"
                >
                  {{ text }}
                </v-chip>

                <span
                  v-else-if="index === 2"
                  class="text-overline grey--text text--darken-4 mx-2"
                >
                  +{{ quizfiles.length - 2 }} File(s)
                </span>
              </template>
            </v-file-input>

            <v-alert
              dense
              text
              class="mt-2"
            >
              Shown below is the excel format with one row of sample data.
            </v-alert>

            <v-data-table
              dense
              :headers="quizHeaders"
              :items="quizDetails"
              hide-default-footer
              item-key="name"
              class="elevation-0"
              style="width: 100%;"
            >
            </v-data-table>
          </v-card-text>

          <v-card-actions class="dense">
              <v-btn class="ml-2" color="primary" :disabled='!quizUpdateEnable' @click="markQuizScore">
                Upload
              </v-btn>
              <p class="mt-3 ml-4 mr-2 text-right" style="width: 100%;">
                Processed  <strong class="primary--text"> {{this.addedQuiz}} </strong>. 
                Failed <strong class="primary--text"> {{this.failedQuiz}} </strong>
              </p>
          </v-card-actions>
        </v-card>
      </v-tab-item>

      <v-tab-item
        key="3"
        value="tab-3"
      >
        <v-card flat>
          <v-card-title>
            Upload Assignment Scores
          </v-card-title>

          <v-row class="ml-3 mr-3">
            <v-col
              md="6"
              sm="12"
              cols="12"
            >
              <v-select
                :items="assignments"
                label="Select Assignment"
                item-text="assignmentName"
                item-value="id"
                persistent-hint
                single-line
                return-object
                v-model="selectedAssignment"
              >
              </v-select>
            </v-col>

            <v-col
              cols="12"
              sm="12"
              md="6"
            >
              <v-menu
                v-model="openMenu2"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="assignmentDate"
                    label="Assignment Date"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="assignmentDate"
                  @input="openMenu2 = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>

          <v-card-text>
            <v-file-input
              v-model="assignmentFiles"
              counter
              label="File input"
              multiple
              placeholder="Select your files"
              outlined
              :show-size="1000"
            >
              <template v-slot:selection="{ index, text }">
                <v-chip
                  v-if="index < 2"
                  label
                  small
                  color="primary"
                >
                  {{ text }}
                </v-chip>

                <span
                  v-else-if="index === 2"
                  class="text-overline grey--text text--darken-4 mx-2"
                >
                  +{{ assignmentFiles.length - 2 }} File(s)
                </span>
              </template>
            </v-file-input>

            <v-alert
              color="accent"
              dense
              text
              class="mt-2"
            >
              Shown below is the excel format with one row of sample data. 
              <strong> For discontinued students please do not fill anything in the grade column.</strong>
            </v-alert>

            <v-data-table
              dense
              :headers="assignmentHeaders"
              :items="assignmentDetails"
              hide-default-footer
              item-key="name"
              class="elevation-0"
              style="width: 100%;"
            >
            </v-data-table>
          </v-card-text>

          <v-card-actions class="dense">
              <v-btn class="ml-2" color="primary" :disabled='!assignmentUpdateEnable' @click="markAssignmentScore">
                Upload
              </v-btn>
              <p class="mt-3 ml-4 mr-2 text-right" style="width: 100%;">
                Processed  <strong class="primary--text"> {{this.addedAssignment}} </strong>. 
                Failed <strong class="primary--text"> {{this.failedAssignment}} </strong>
              </p>
          </v-card-actions>
        </v-card>
      </v-tab-item>

      <v-tab-item
        key="4"
        value="tab-4"
      >
        <v-card flat>
          <v-card-title>
            Add Students to a Course
          </v-card-title>

          <v-card-text>
            <v-select
              :items="courses"
              label="Select Course"
              item-text="courseName"
              item-value="id"
              persistent-hint
              single-line
              return-object
              v-model="selectedCourse"
            >
            </v-select>
          </v-card-text>

          <v-card-text>
            <v-file-input
              v-model="courseFiles"
              counter
              label="File input"
              multiple
              placeholder="Select your files"
              outlined
              :show-size="1000"
            >
              <template v-slot:selection="{ index, text }">
                <v-chip
                  v-if="index < 2"
                  label
                  small
                  color="primary"
                >
                  {{ text }}
                </v-chip>

                <span
                  v-else-if="index === 2"
                  class="text-overline grey--text text--darken-4 mx-2"
                >
                  +{{ courseFiles.length - 2 }} File(s)
                </span>
              </template>
            </v-file-input>

            <v-alert
              dense
              text
              class="mt-2"
            >
              Shown below is the excel format with one row of sample data.
            </v-alert>

            <v-data-table
              dense
              :headers="uploadHeaders"
              :items="uploadDetails"
              hide-default-footer
              item-key="name"
              class="elevation-0"
              style="width: 100%;"
            >
            </v-data-table>
          </v-card-text>

          <v-card-actions class="dense">
              <v-btn class="ml-2" color="primary" :disabled='!courseUpdateEnable' @click="uploadCourseStudent">
                Upload
              </v-btn>
              <p class="mt-3 ml-4 mr-2 text-right" style="width: 100%;">
                Processed  <strong class="primary--text"> {{this.added}} </strong>. 
                Failed <strong class="primary--text"> {{this.failed}} </strong>
              </p>
          </v-card-actions>
        </v-card>
      </v-tab-item>

      <v-tab-item
        key="5"
        value="tab-5"
      >
        <v-card flat>
          <v-card-title>
            Upload MOOC Data
          </v-card-title>


          <v-row class="ml-3 mr-3">
            <v-col
              lg="4"
              md="6"
              sm="12"
              cols="12"
            >
              <v-select
                :items="courses"
                label="Select Course"
                item-text="courseName"
                item-value="id"
                persistent-hint
                single-line
                return-object
                v-model="selectedCourse"
              >
              </v-select>
            </v-col>

            <v-col
              lg="4"
              md="6"
              sm="12"
              cols="12"
            >
              <v-text-field
                v-model="moduleName"
                label="Module Name"
              ></v-text-field>
            </v-col>

            <v-col
              lg="4"
              cols="12"
              sm="12"
              md="6"
            >
              <v-menu
                v-model="openMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="moocDate"
                    label="MOOC Date"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="moocDate"
                  @input="openMenu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>

          <v-card-text>
            <v-file-input
              v-model="moocFiles"
              counter
              label="File input"
              multiple
              placeholder="Select your files"
              outlined
              :show-size="1000"
            >
              <template v-slot:selection="{ index, text }">
                <v-chip
                  v-if="index < 2"
                  label
                  small
                  color="primary"
                >
                  {{ text }}
                </v-chip>

                <span
                  v-else-if="index === 2"
                  class="text-overline grey--text text--darken-4 mx-2"
                >
                  +{{ moocFiles.length - 2 }} File(s)
                </span>
              </template>
            </v-file-input>

            <v-alert
              dense
              text
              class="mt-2"
            >
              Shown below is the excel format with one row of sample data.
            </v-alert>

            <v-data-table
              dense
              :headers="moocHeaders"
              :items="moocDetails"
              hide-default-footer
              item-key="name"
              class="elevation-0"
              style="width: 100%;"
            >
            </v-data-table>
          </v-card-text>

          <v-card-actions class="dense">
              <v-btn class="ml-2" color="primary" :disabled='!moocUpdateEnable' @click="uploadMooc">
                Upload
              </v-btn>
              <p class="mt-3 ml-4 mr-2 text-right" style="width: 100%;">
                Processed  <strong class="primary--text"> {{this.added}} </strong>. 
                Failed <strong class="primary--text"> {{this.failed}} </strong>
              </p>
          </v-card-actions>
        </v-card>
      </v-tab-item>
    </v-tabs-items>

  </div>
</template>

<script>
  
  import { readExcel, readFileAsync } from '../../utils/xlsUtils'
  import {courseStudentsToAPI, attendanceToAPI, quizToAPI, assignmentToAPI, moocToAPI } from '../../dataTransformations'

  const uploadDetails = [
    {
      erp: 'BAI13010288',
      roll: 'BAI13005',
      name: 'Max Planck',
      email: 'max.planck@iimb.ac.in',
      gender: 'M'
    }
  ]

  const moocDetails = [
    {
      email: 'max.planck@iimb.ac.in',
      completionPct: '93%',
      marks: '90%',
      status: 'Complete'
    }
  ]

  const attendanceDetails = [
    {
      roll: 'BAI12014',
      date: '2022-07-28',
      present: 'Y',
      session: 'S1'
    }
  ]

  const uploadHeaders = [
    {
      text: 'Application No',
      align: 'start',
      value: 'erp',
    },
    { text: 'Student Roll No.', value: 'roll'},
    { text: 'Name', value: 'name', width: '110' },
    { text: 'Email ID', value: 'email' },
    { text: 'Gender', value: 'gender' }
  ]

  const moocHeaders = [
    {
      text: 'Email id',
      align: 'start',
      value: 'email',
    },
    { text: 'Course content completed (%)', value: 'completionPct'},
    { text: 'Final Exam', value: 'marks' },
    { text: 'Status', value: 'status' }
  ]

  const attendanceHeaders = [
    { text: 'Student Roll No', value: 'roll'},
    { text: 'Date', value: 'date' },
    { text: 'Session', value: 'session'},
    { text: 'Attendance Status', value: 'present', align: 'start' }
  ]

  const quizHeaders = [
    { text: 'ROLL NO', value: 'roll'},
    { text: 'Final Score', value: 'marks' },
    { text: 'Grade', value: 'grade' },
  ]

  const assignmentHeaders = [
    { text: 'ROLL NO', value: 'roll'},
    { text: 'Average %', value: 'marks' },
    { text: 'Grade', value: 'grade' },
  ]

  const assignmentDetails = [
    {
      roll: 'BAI12007',
      marks: 68.7,
      grade: 'C'
    }
  ]

  const quizDetails = [
    {
      roll: 'BAI12007',
      marks: 30,
      grade: 'C'
    }
  ]

  import {
    mdiMagnify,
  } from '@mdi/js'

  export default {
    mounted(){
      this.$store.dispatch('fetchCourses')
      this.$store.dispatch('fetchQuizes')
      this.$store.dispatch('fetchAssignments')
    },
    data () {
      return {
        icons: {
          mdiMagnify,
        },
        tab: null,
        moocFiles: [],
        courseFiles: [],
        attendanceFiles: [],
        quizfiles: [],
        assignmentFiles: [],
        attendanceDetails,
        uploadDetails,
        moocDetails,
        uploadHeaders,
        moocHeaders,
        attendanceHeaders,
        quizHeaders,
        quizDetails,
        assignmentHeaders,
        assignmentDetails,
        selectedCourse: {id: null},
        selectedQuiz : {id: null},
        selectedAssignment: {id: null},
        openMenu: false,
        openMenu2: false,
        quizDate: null,
        moocDate: null,
        assignmentDate: null,
        moduleName: '',
        added: 0,
        failed: 0,
        addedAssignment: 0,
        failedAssignment: 0,
        addedAttendance: 0, 
        failedAttendance: 0,
        addedQuiz: 0,
        failedQuiz: 0,
        attendanceDate: null
      }
    },
    computed: {
      courses(){
        return this.$store.getters.getCourses
      },
      quizes(){
        return this.$store.getters.getQuizes
      },
      assignments(){
        return this.$store.getters.getAssignments
      },
      moocUpdateEnable(){
        if(this.selectedCourse && this.selectedCourse.id && this.moocFiles && this.moocFiles.length > 0 && this.moocDate && this.moduleName){
          return true
        }        
        return false
      },
      courseUpdateEnable(){
        if(this.selectedCourse && this.selectedCourse.id && this.courseFiles && this.courseFiles.length > 0){
          return true
        }        
        return false
      },
      attendanceUpdateEnable(){
        if(this.attendanceFiles && this.attendanceFiles.length > 0){
          return true
        }        
        return false
      },
      quizUpdateEnable(){
        if(this.quizfiles && this.quizfiles.length > 0 && this.selectedQuiz && this.selectedQuiz.id && this.quizDate){
          return true
        }        
        return false
      },
      assignmentUpdateEnable(){
        if(this.assignmentFiles && this.assignmentFiles.length > 0 && this.selectedAssignment && this.selectedAssignment.id && this.assignmentDate){
          return true
        }        
        return false 
      }
    },
    methods: {
      async uploadMooc(){
        this.added = 0
        this.failed = 0

        try{
          const excelBuffer = await readFileAsync(this.moocFiles[0])
          const excelData = readExcel(excelBuffer)
          
          for(let i = 0; i < excelData.length; i++){
            excelData[i]['course'] = this.selectedCourse.id
            excelData[i]['date'] = this.moocDate
            excelData[i]['moduleName'] = this.moduleName
            const added = await moocToAPI(excelData[i])

            if(added){
              this.added++
            }
            else{
              this.failed++
            }
          }
        }
        catch(ex) {
          console.log(ex)
          //Error reading file.
        }
      },
      async uploadCourseStudent(){
        this.added = 0
        this.failed = 0

        try{
          const excelBuffer = await readFileAsync(this.courseFiles[0])
          const excelData = readExcel(excelBuffer)
          
          for(let i = 0; i < excelData.length; i++){
            excelData[i]['course'] = this.selectedCourse.id
            const added = await courseStudentsToAPI(excelData[i])

            if(added){
              this.added++
            }
            else{
              this.failed++
            }
          }
        }
        catch(ex) {
          console.log(ex)
          //Error reading file.
        }
      },
      async uploadAttendanceStudent(){
        this.addedAttendance = 0
        this.failedAttendance = 0

        try{
          const excelBuffer = await readFileAsync(this.attendanceFiles[0])
          const excelData = readExcel(excelBuffer)
          
          for(let i = 0; i < excelData.length; i++){
            const added = await attendanceToAPI(excelData[i])

            if(added){
              this.addedAttendance++
            }
            else{
              this.failedAttendance++
            }
          }
        }
        catch(ex) {
          console.log(ex)
          //Error reading file.
        }
      },
      async markQuizScore(){
        this.addedQuiz = 0
        this.failedQuiz = 0

        try{
          const excelBuffer = await readFileAsync(this.quizfiles[0])
          const excelData = readExcel(excelBuffer)
          
          for(let i = 0; i < excelData.length; i++){
            excelData[i]['date'] = this.quizDate
            excelData[i]['id'] = this.selectedQuiz.id
            const added = await quizToAPI(excelData[i])

            if(added){
              this.addedQuiz++
            }
            else{
              this.failedQuiz++
            }
          }
        }
        catch(ex) {
          console.log(ex)
          //Error reading file.
        }
      },
      async markAssignmentScore(){
        this.addedAssignment = 0
        this.failedAssignment = 0

        try{
          const excelBuffer = await readFileAsync(this.assignmentFiles[0])
          const excelData = readExcel(excelBuffer)
          
          for(let i = 0; i < excelData.length; i++){
            excelData[i]['date'] = this.assignmentDate
            excelData[i]['id'] = this.selectedAssignment.id
            const added = await assignmentToAPI(excelData[i])

            if(added){
              this.addedAssignment++
            }
            else{
              this.failedAssignment++
            }
          }
        }
        catch(ex) {
          console.log(ex)
          //Error reading file.
        }
      },      
    },
  }
</script>

<style>

  .v-data-table-header th {
    white-space: nowrap;
  }

</style>
