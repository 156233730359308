import {addStudentToCourse, markQuizScore, markAttendance, markAssignmentScore, addMoocScore} from '../api/course' 
import moment from 'moment';

export const courseStudentsToAPI = async (data) => {
    const apiData = {
        rollNumber : data['Student Roll No.'],
        name: data['Name'],
        email: data['Email ID'],
        course: data['course'],
        gender: data['Gender']
    }

    try{
        const returnValue = await addStudentToCourse(apiData)

        if(returnValue.code === 400)
            return (false)

        return (returnValue.success)
    }
    catch(err) {
        return (false)
    }
}

export const moocToAPI = async (data) => {
    let marks;

    try{
        const m = data['Final Exam'] ? data['Final Exam'].replace('%', ''): null
        marks = m && parseFloat(m) <= 1 ? parseFloat(m) * 100 : parseFloat(m)
    }
    catch(err){
        marks = null
    }
    
    const completion = data['Course content completed (%)'] ? data['Course content completed (%)'].replace('%', ''): null;
    const completionPct = completion <= 1 ? completion * 100 : completion

    const apiData = {
        completionPct : completionPct,
        marks:marks,
        status: data['Status'],
        email: data['Email id'] || data['Email ID'] || data['Email Id'],
        date: data['date'],
        course: data['course'],
        moduleName: data['moduleName']
    }

    try{
        const returnValue = await addMoocScore(apiData)

        if(returnValue.code === 400)
            return (false)

        return (returnValue.success)
    }
    catch(err) {
        return (false)
    }
}

function SerialDateToJSDate(excelSerialDate) {
    return new Date(Date.UTC(0, 0, excelSerialDate - 1));
}

function SerialDateToISODateString(excelSerialDate) {
    return SerialDateToJSDate(excelSerialDate).toISOString().split('T')[0];
}

export const attendanceToAPI = async (data) => {

    try{
        let isPresent = false;

        if(data['Attendance Status'] && data['Attendance Status'].toUpperCase() === 'Y'){
            isPresent = true;
        }
        else{
            console.log(data)
        }

        let date = data['Date']
        if(typeof date === 'number') { //We are getting excel number for date
            date = SerialDateToISODateString(date)
        }
    
        if(date.indexOf('/') !== -1){
            date =  moment(date, "DD/MM/YYYY").format('YYYY-MM-DD')
        }
        else{
            if(date.indexOf('-') === 2){
                date =  moment(date, "DD-MM-YYYY").format('YYYY-MM-DD')
            }
        }
       
        const apiData = {
            rollNumber : data['Student Roll No'],
            date: date,
            isPresent: isPresent,
            sessionName: data['Session']
        }

        const returnValue = await markAttendance(apiData)

        if(returnValue.code === 400)
            return (false)

        return (returnValue.success)
    }
    catch(err) {
        return (false)
    }
}

export const quizToAPI = async (data) => {
    
    const apiData = {
        rollNumber : data['ROLL NO'],
        marks: data['Final Score'],
        date: data['date'],
        quizId: data['id'],
        grade: data['Grade'] ? data['Grade'].toUpperCase() : null
    }

    if(!apiData.quizId) {
        return (false)
    }

    try{
        const returnValue = await markQuizScore(apiData)
        
        if(returnValue.code === 400)
            return (false)
        
        return (returnValue.success)
    }
    catch(err) {
        return (false)
    }
}

export const assignmentToAPI = async (data) => {
    
    const apiData = {
        rollNumber : data['ROLL NO'],
        marks: data['Average %'],
        date: data['date'],
        assignmentId: data['id'],
        grade: data['Grade'] ? data['Grade'].toUpperCase() : null
    }

    if(!apiData.assignmentId) {
        return (false)
    }

    try{
        const returnValue = await markAssignmentScore(apiData)
        
        if(returnValue.code === 400)
            return (false)
        
        return (returnValue.success)
    }
    catch(err) {
        return (false)
    }
}